import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-sidenav',
  templateUrl: './page-sidenav.component.html',
  styleUrls: ['./page-sidenav.component.scss'],
})
export class PageSidenavComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
